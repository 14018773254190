var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import EPlayerRole from "cresus-common/dist/dto/EPlayerRole";
import * as mediasoupClient from "mediasoup-client";
import protooClient from "protoo-client";
// TODO move this elsewhere, could be useful
function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
function sleep(fn, ...args) {
    return __awaiter(this, void 0, void 0, function* () {
        yield timeout(3000);
        return fn(...args);
    });
}
class AudioVideoClient {
    constructor(sessionId, token, role, sessionPeer) {
        this.onNewConsumer = []; // TODO meh using an array for this is not 
        this.onConsumerClosed = []; // TODO meh
        this._consumers = new Map();
        this._producer = null; // Microphone producer
        this._webcamProducer = null;
        this._mediasoupDevice = new mediasoupClient.Device();
        this.onMuteChange = [];
        this.onMuteChangeOthers = [];
        window.addEventListener("beforeunload", (event) => {
            this.close();
        });
        this._role = role;
        this._sessionPeer = sessionPeer;
        const protocol = "wss"; // TODO WSS 
        const url = `${protocol}://${process.env.REACT_APP_MEDIAAPI_URL}/websocket?sessionId=${sessionId}&token=${token}`;
        const websocketTransport = new protooClient.WebSocketTransport(url);
        this.peer = new protooClient.Peer(websocketTransport);
        console.log("CREATING PEER");
        this.peer.on("open", () => __awaiter(this, void 0, void 0, function* () {
            yield this.setupWebRTCTransport();
            this.peerId = yield this.peer.request("getPeerId").catch((err) => {
                console.error("Error getting peerId", err);
            });
            if (role !== EPlayerRole.ANIMATOR) // We don't need to associate media to user if we are the animator
                yield this.associateMediaToUser();
        }));
        this.peer.on("request", (request, accept, reject) => __awaiter(this, void 0, void 0, function* () {
            console.log("REQUEST", request.method);
            switch (request.method) {
                case 'newConsumer':
                    {
                        // if (!this._consume)
                        // {
                        //     reject(403, 'I do not want to consume');
                        //     break;
                        // }
                        const { peerId, producerId, id, kind, rtpParameters, type, appData, producerPaused } = request.data;
                        try {
                            const consumer = yield this._recvTransport.consume({
                                id,
                                producerId,
                                kind,
                                rtpParameters,
                                // NOTE: Force streamId to be same in mic and webcam and different
                                // in screen sharing so libwebrtc will just try to sync mic and
                                // webcam streams from the same remote peer.
                                streamId: `${peerId}-${appData.share ? 'share' : 'mic-webcam'}`,
                                appData: Object.assign(Object.assign({}, appData), { peerId }) // Trick.
                            });
                            // Store in the map.
                            this._consumers.set(consumer.id, consumer);
                            consumer.on('transportclose', () => {
                                this._consumers.delete(consumer.id);
                            });
                            this.peerId = yield this.peer.request("getPeerId").catch((err) => {
                                console.error("Error getting peerId", err);
                            });
                            console.log(`MEDIAAPI NEWCONSUMER selfPeerId${this.peerId} newConsumerPeerId${consumer.appData.peerId}`);
                            // To prevent creating an audio tag for ourself
                            // but we allow creating a self video tag
                            const isSelf = this.peerId && consumer.appData.peerId === this.peerId;
                            if ((consumer.track.kind === 'audio' && !isSelf) || consumer.track.kind === 'video') {
                                console.log("NEW CONSOMER callback called");
                                this.onNewConsumer.forEach((callback) => callback({ peerId: peerId, consumer: consumer }));
                            }
                            // store.dispatch(stateActions.addConsumer(
                            //     {
                            //         id                     : consumer.id,
                            //         type                   : type,
                            //         locallyPaused          : false,
                            //         remotelyPaused         : producerPaused,
                            //         rtpParameters          : consumer.rtpParameters,
                            //         spatialLayers          : spatialLayers,
                            //         temporalLayers         : temporalLayers,
                            //         preferredSpatialLayer  : spatialLayers - 1,
                            //         preferredTemporalLayer : temporalLayers - 1,
                            //         priority               : 1,
                            //         codec                  : consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
                            //         track                  : consumer.track
                            //     },
                            //     peerId));
                            // We are ready. Answer the protoo request so the server will
                            // resume this Consumer (which was paused for now if video).
                            accept();
                            // If audio-only mode is enabled, pause it.
                            // if (consumer.kind === 'video' && store.getState().me.audioOnly)
                            // this._pauseConsumer(consumer); // TODO handle mute
                            consumer.resume();
                        }
                        catch (error) {
                            console.error('"newConsumer" request failed:%o', error);
                            throw error;
                        }
                        break;
                    }
                default: {
                    console.log("Received unknown request", request.method);
                    break;
                }
            }
        }));
        this.peer.on('failed', (err) => {
            console.log("ProtooClient:FAILED", err);
        });
        this.peer.on("notification", (notification) => {
            switch (notification.method) {
                case 'consumerClosed':
                    {
                        const { consumerId } = notification.data;
                        const consumer = this._consumers.get(consumerId);
                        if (!consumer)
                            break;
                        consumer.close();
                        this._consumers.delete(consumerId);
                        const { peerId } = consumer.appData;
                        this.onConsumerClosed.forEach((callback) => callback(consumer));
                        // store.dispatch(
                        //     stateActions.removeConsumer(consumerId, peerId));
                        break;
                    }
                case 'consumerPaused':
                    {
                        const { consumerId } = notification.data;
                        const consumer = this._consumers.get(consumerId);
                        if (!consumer)
                            break;
                        consumer.pause();
                        console.log("CONSUMER PAUSED", consumerId);
                        // store.dispatch(
                        //     stateActions.setConsumerPaused(consumerId, 'remote'));
                        break;
                    }
                case 'consumerResumed':
                    {
                        const { consumerId } = notification.data;
                        const consumer = this._consumers.get(consumerId);
                        if (!consumer) {
                            console.log("CONSUMER NOT FOUND");
                            break;
                        }
                        consumer.resume();
                        console.log("CONSUMER RESUMED", consumerId);
                        // store.dispatch(
                        //     stateActions.setConsumerResumed(consumerId, 'remote'));
                        break;
                    }
                case 'muteMicrophone': {
                    this.onMuteChange.forEach(el => el(true));
                    console.log("Received muteMicrophone request");
                    this.muteMic();
                    break;
                }
                case 'unmuteMicrophone': {
                    this.onMuteChange.forEach(el => el(false));
                    console.log("Received unmuteMicrophone request");
                    this.unmuteMic();
                    break;
                }
            }
        });
        this.peer.on("close", () => {
            this.close();
        });
        this._sessionPeer.on("notification", (notification) => {
            switch (notification.method) {
                case 'changeMicrophoneOthers': { // Received when the animator change the setting for the microphone of a participant
                    console.log("Received changeMicrophoneOthers request");
                    this.onMuteChangeOthers.forEach(el => el(notification.data.peerId, notification.data.muted));
                    break;
                }
            }
        });
    }
    getMuted(mediaAPIPeerId) {
        console.log("getMuted", this._consumers);
        const consumer = Array.from(this._consumers.values()).find(el => el.appData.peerId == mediaAPIPeerId);
        if (consumer) {
            return consumer.track.muted;
        }
        return false;
    }
    close() {
        var _a, _b;
        console.debug('close()');
        this.ResetConsumers();
        // Close protoo Peer
        this.peer.close();
        // Close mediasoup Transports.
        if (this._sendTransport)
            this._sendTransport.close();
        if (this._recvTransport)
            this._recvTransport.close();
        this._recvTransport = undefined;
        this._sendTransport = undefined;
        (_a = this._producer) === null || _a === void 0 ? void 0 : _a.close();
        this._producer = null;
        (_b = this._webcamProducer) === null || _b === void 0 ? void 0 : _b.close();
        this._webcamProducer = null;
        this._consumers.forEach((consumer) => {
            consumer.close();
        });
        this._consumers.clear();
    }
    ResetConsumers() {
        // TODO unsure it's the right way to do it / probably useless
        console.log("Resetting consumers 2");
        this._consumers.forEach((consumer) => {
            consumer.close();
        });
        this._consumers.clear();
    }
    associateMediaToUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const peerId = yield this.peer.request("getPeerId");
            console.log("associateMediaToUser", peerId);
            yield this._sessionPeer.request("associateMediaToUser", {
                mediaAPIPeerId: peerId
            }).catch((err) => {
                console.error("Error associating media to user", err);
            });
        });
    }
    setupWebRTCTransport() {
        return __awaiter(this, void 0, void 0, function* () {
            // 1st step is to get the routerRtpCapabilities to configure the mediasoup client device
            console.log("Requesting routerRTPCapabilities");
            const routerRTPCapabilities = yield this.peer.request("getRouterRtpCapabilities"); // TODO missing parameter ?
            let device = new mediasoupClient.Device();
            this._mediasoupDevice = device;
            yield device.load({ routerRtpCapabilities: routerRTPCapabilities });
            console.log("Loaded device with routerRTPCapabilities", routerRTPCapabilities);
            // ========== 2nd step is to create the send transport
            // ===== Send Transport
            // NOTE: Stuff to play remote audios due to browsers' new autoplay policy.
            //
            // Just get access to the mic and DO NOT close the mic track for a while.
            // Super hack!
            {
                const stream = yield navigator.mediaDevices.getUserMedia({ audio: true });
                const audioTrack = stream.getAudioTracks()[0];
                audioTrack.enabled = false;
                setTimeout(() => audioTrack.stop(), 120000);
            }
            const sendTransportResult = yield this.peer.request("createWebRTCTransport", {
                forceTcp: false,
                producing: true,
                consuming: false,
                sctpCapabilities: undefined // We don't use data channels
            }).catch((err) => {
                console.error("Error creating send transport", err);
            });
            console.log("createWebRTCTransport", sendTransportResult);
            this._sendTransport = device.createSendTransport({
                id: sendTransportResult.id,
                iceParameters: sendTransportResult.iceParameters,
                iceCandidates: sendTransportResult.iceCandidates,
                dtlsParameters: Object.assign(Object.assign({}, sendTransportResult.dtlsParameters), { 
                    // Remote DTLS role. We know it's always 'auto' by default so, if
                    // we want, we can force local WebRTC transport to be 'client' by
                    // indicating 'server' here and vice-versa.
                    role: 'auto' }),
                sctpParameters: sendTransportResult.sctpParameters,
                iceServers: [],
                proprietaryConstraints: {},
                additionalSettings: { encodedInsertableStreams: false } // Should work without, will think if we need it ?
            });
            console.log("Created send transport", this._sendTransport);
            this._sendTransport.on('connectionstatechange', (connectionState) => {
                console.log("CONNECTION STATE CHANGED", connectionState);
            });
            this._sendTransport.on('connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
             {
                console.log("CONNECTING", dtlsParameters);
                this.peer.request('connectWebRtcTransport', {
                    transportId: this._sendTransport.id,
                    dtlsParameters
                })
                    .then(callback)
                    .catch(errback);
            });
            this._sendTransport.on('produce', ({ kind, rtpParameters, appData }, callback, errback) => __awaiter(this, void 0, void 0, function* () {
                try {
                    console.log("PRODUCING");
                    // eslint-disable-next-line no-shadow
                    const { id } = yield this.peer.request('produce', {
                        transportId: this._sendTransport.id,
                        kind,
                        rtpParameters,
                        appData
                    });
                    console.log("PRODUCED id", id);
                    callback({ id });
                }
                catch (error) {
                    if (error instanceof Error)
                        errback(error);
                }
            }));
            // ===== Receive Transport
            const transportInfo = yield this.peer.request('createWebRTCTransport', {
                forceTcp: false,
                producing: false,
                consuming: true,
                sctpCapabilities: undefined // We don't use data channels
            });
            const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = transportInfo;
            console.log("recv transport parameters", transportInfo);
            this._recvTransport = device.createRecvTransport({
                id,
                iceParameters,
                iceCandidates,
                dtlsParameters: Object.assign(Object.assign({}, dtlsParameters), { 
                    // Remote DTLS role. We know it's always 'auto' by default so, if
                    // we want, we can force local WebRTC transport to be 'client' by
                    // indicating 'server' here and vice-versa.
                    role: 'auto' }),
                sctpParameters,
                iceServers: [],
                additionalSettings: { encodedInsertableStreams: false }
            });
            console.log("recvTrans.on(connect)");
            this._recvTransport.on('connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
             {
                console.log("CONNECTING RECV");
                this.peer.request('connectWebRtcTransport', {
                    transportId: this._recvTransport.id,
                    dtlsParameters
                })
                    .then(callback)
                    .catch(errback);
            });
            // 4th step is to create the consumers
            // Join now into the room.
            // NOTE: Don't send our RTP capabilities if we don't want to consume.
            console.log("JOINING");
            const { peers } = yield this.peer.request('join', {
                displayName: "UserTest123" + new Date().toString(),
                device: device,
                rtpCapabilities: device.rtpCapabilities,
                sctpCapabilities: undefined // We don't use data channels
            });
            //await timeout(3000); // TODO need to wait properly for tthe peer to have joined the room
            // // Unmute mic
            console.log("3rd step is to create the producer");
            // 3rd step is to create the producer
            const track = yield navigator.mediaDevices.getUserMedia({ video: false, audio: true });
            const currenttrack = track.getTracks()[0];
            console.log("gottrack");
            const producer = yield this._sendTransport.produce({
                track: currenttrack,
                codecOptions: {
                    opusStereo: true,
                    opusDtx: true,
                    opusFec: true,
                    opusNack: true
                }
            });
            console.log("Producer created with device", currenttrack.label);
            this._producer = producer;
            try {
                yield this.peer.request('resumeProducer', { producerId: producer.id });
                producer.resume();
            }
            catch (error) {
                console.error('resumeProducer() failed:%o', error);
            }
            if (this._role === EPlayerRole.ANIMATOR)
                this.enableWebcam();
            // TODO put this behind a DEBUG / DEV flag
            // setInterval(async () => {
            //     let res = await this.peer.notify("getSendTransportStats")
            //     console.log(res);
            // }, 5000);
        });
    }
    muteParticipant(peerId, sessionPeerId) {
        this.peer.notify("muteParticipant", { peerId: peerId });
        this._sessionPeer.notify("muteParticipant", { peerId: sessionPeerId });
    }
    // Mute user's mic
    muteMic() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                console.debug('muteMic()');
                this._sessionPeer.notify('muteSelf').catch((err) => {
                    console.error("Error notifying muteSelf", err);
                });
                (_a = this._producer) === null || _a === void 0 ? void 0 : _a.pause();
                console.log("PAUSING PRODUCER", (_b = this._producer) === null || _b === void 0 ? void 0 : _b.id);
                yield this.peer.request('pauseProducer', { producerId: (_c = this._producer) === null || _c === void 0 ? void 0 : _c.id }).catch((err) => {
                    console.error("Error this.peer.request", err);
                });
            }
            catch (error) {
                console.error('muteMic() | failed: %o', error);
            }
        });
    }
    unmuteMic() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            console.log("UNMUTE MIC");
            try {
                console.debug('unmuteMic()');
                this._sessionPeer.notify('unmuteSelf').catch((err) => {
                    console.error("Error notifying unmuteSelf", err);
                });
                (_a = this._producer) === null || _a === void 0 ? void 0 : _a.resume();
                yield this.peer.request('resumeProducer', { producerId: (_b = this._producer) === null || _b === void 0 ? void 0 : _b.id });
            }
            catch (error) {
                console.error('unmuteMic() | failed: %o', error);
            }
        });
    }
    enableWebcam() {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('enableWebcam()');
            if (this._webcamProducer || !this._mediasoupDevice.loaded)
                return;
            if (!this._mediasoupDevice.canProduce('video')) {
                console.error('enableWebcam() | cannot produce video');
                return;
            }
            let track;
            let device;
            try {
                {
                    // await this._updateWebcams(); // TODO get camera from user selection
                    let devices = yield navigator.mediaDevices.enumerateDevices();
                    device = devices.find((device) => device.kind === 'videoinput'); // For now selecting first video device available
                    // const { resolution } = this._webcam;
                    const resolution = "lowhd"; //"hd";
                    const VIDEO_CONSTRAINS = {
                        qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
                        vga: { width: { ideal: 640 }, height: { ideal: 480 } },
                        lowhd: { width: { ideal: 960 }, height: { ideal: 540 } },
                        hd: { width: { ideal: 1280 }, height: { ideal: 720 } }
                    };
                    if (!device)
                        throw new Error('no webcam devices');
                    console.debug('enableWebcam() | calling getUserMedia()');
                    const stream = yield navigator.mediaDevices.getUserMedia({
                        video: Object.assign({ deviceId: { ideal: device.deviceId } }, VIDEO_CONSTRAINS[resolution])
                    });
                    track = stream.getVideoTracks()[0];
                }
                let encodings;
                let codec;
                const codecOptions = {
                    videoGoogleStartBitrate: 1000
                };
                this._webcamProducer = yield this._sendTransport.produce({
                    track,
                    encodings,
                    codecOptions,
                    codec
                });
                this._sessionPeer.notify("enableWebcam").catch((err) => {
                    console.error("Error notifying enableWebcam", err);
                });
                this._webcamProducer.on('transportclose', () => {
                    this._webcamProducer = null;
                });
                this._webcamProducer.on('trackended', () => {
                    this.disableWebcam()
                        .catch(() => { });
                });
            }
            catch (error) {
                console.error('enableWebcam() | failed:%o', error);
                if (track)
                    track.stop();
            }
        });
    }
    disableWebcam() {
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('disableWebcam()');
            if (!this._webcamProducer)
                return;
            this._webcamProducer.close();
            try {
                yield this.peer.request('closeProducer', { producerId: this._webcamProducer.id });
            }
            catch (error) {
                console.error("Error closing server-side webcam Producer: ", error);
            }
            this._webcamProducer = null;
        });
    }
    changeWebcam(deviceId) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('changeWebcam()');
            // store.dispatch(
            // 	stateActions.setWebcamInProgress(true));
            try {
                console.debug('changeWebcam() | new selected webcam [device:%o]', deviceId);
                // TODO get stream from deviceID
                // Closing the current video track before asking for a new one (mobiles do not like
                // having both front/back cameras open at the same time).
                (_b = (_a = this._webcamProducer) === null || _a === void 0 ? void 0 : _a.track) === null || _b === void 0 ? void 0 : _b.stop();
                console.debug('changeWebcam() | calling getUserMedia()');
                const stream = yield navigator.mediaDevices.getUserMedia({
                    video: {
                        deviceId: { exact: deviceId },
                        // ...VIDEO_CONSTRAINS[this._webcam.resolution] // TODO share video constraints with enableWebcam
                    }
                });
                const track = stream.getVideoTracks()[0];
                yield ((_c = this._webcamProducer) === null || _c === void 0 ? void 0 : _c.replaceTrack({ track }));
                // store.dispatch(
                // 	stateActions.setProducerTrack(this._webcamProducer.id, track));
            }
            catch (error) {
                console.error('changeWebcam() | failed: %o', error);
            }
        });
    }
    changeMicrophone(deviceId) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            console.debug('changeMicrophone()');
            // store.dispatch(
            // 	stateActions.setWebcamInProgress(true));
            try {
                console.debug('changeMicrophone() | new selected micro [device:%o]', deviceId);
                // TODO get stream from deviceID
                // Closing the current video track before asking for a new one (mobiles do not like
                // having both front/back cameras open at the same time).
                (_b = (_a = this._producer) === null || _a === void 0 ? void 0 : _a.track) === null || _b === void 0 ? void 0 : _b.stop();
                console.debug('changeMicrophone() | calling getUserMedia()');
                const stream = yield navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: { exact: deviceId },
                    }
                });
                const track = stream.getAudioTracks()[0];
                yield ((_c = this._producer) === null || _c === void 0 ? void 0 : _c.replaceTrack({ track }));
                // store.dispatch(
                // 	stateActions.setProducerTrack(this._webcamProducer.id, track));
            }
            catch (error) {
                console.error('changeMicrophone() | failed: %o', error);
            }
        });
    }
}
export default AudioVideoClient;
